/* eslint-disable no-loop-func */
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import apiCall from "services/apiCall";
import { useSelector } from "react-redux";
import { RoleTypeEnum } from "constants/enums/RoleEnum";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "constants/enums/FirebaseConfigs";
import { getDatabase, off, onValue, ref } from "firebase/database";
import { getCurrentUserId, getSelectedAuthorization } from "store/slices/userSlice";
import {
  searchInstantMessageGroups,
  getMessageMemberGroupCodes,
  getSchoolInstantMessageConfig
} from "services/instant-message-service";

export const MessagingContext = createContext({
  chatGroups: [],
  canUserCreateMessage: false,
  lastMessages: {},
  lastSeens: {},
  loading: false,
  unseenMessageCount: 0,
  fireDb: null,
  onGroupChatInView: () => null
});

export const useInstantMessageContext = () => useContext(MessagingContext);

export default function InstantMessageProvider({ children }) {
  const userId = useSelector(getCurrentUserId);
  const selectedRole = useSelector(getSelectedAuthorization);
  const fireApp = initializeApp(firebaseConfig);
  const RealTimeDatabase = getDatabase(fireApp);
  const [serviceLoad, setServiceLoad] = useState(false);
  const [chatGroups, setChatGroups] = useState([]);
  const [canUserCreateMessage, setCanUserCreateMessage] = useState(false);
  const [lastMessages, setLastMessages] = useState({});
  const [lastSeens, setLastSeens] = useState({});
  const [unseenMessageCount, setUnseenMessageCount] = useState(0);
  const [currentMessagingGroup, setCurrentMessagingGroup] = useState("");
  const listenerPaths = useRef([]);
  const now = new Date().getTime() + 5000;

  useEffect(() => {
    const initProvider = async () => {
      await Promise.all([getChatGroups(true), getUserCanCreateMessage()]);
    };
    if (selectedRole?.id) initProvider();
    return () => {
      for (const listenerPath of listenerPaths.current) {
        off(ref(RealTimeDatabase, listenerPath));
      }
      listenerPaths.current = [];
    };
  }, [selectedRole?.id]);

  useEffect(() => {
    if (
      chatGroups.length === Object.keys(lastMessages).length &&
      chatGroups.length === Object.keys(lastSeens).length
    ) {
      let unseenCount = 0;
      chatGroups.forEach((cg) => {
        const lastMessage = lastMessages?.[cg?.id];
        const lastSeen = lastSeens?.[cg?.id];
        if (
          lastMessage?.uI !== userId &&
          lastMessage?.d > (lastSeen || 0) &&
          currentMessagingGroup !== cg?.id &&
          chatGroups?.find((group) => group?.id === cg?.id)
        )
          unseenCount += 1;
      });
      setUnseenMessageCount(unseenCount);
    }
  }, [JSON.stringify(lastSeens), JSON.stringify(lastMessages)]);

  const getChatGroups = async (isInit = false) => {
    setServiceLoad(true);
    let groups = [];
    await apiCall({
      service: searchInstantMessageGroups,
      onSuccess: async (response) => {
        groups = response?.data || [];
        setChatGroups(groups);
      }
    }).finally(async () => {
      const groupIds = groups.map((gr) => gr?.id);
      if (isInit) await registerMemberCodeListenners(groupIds);
      await registerGroupListenners(groupIds);
      setServiceLoad(false);
    });
  };

  const getUserCanCreateMessage = async () => {
    try {
      if (
        selectedRole?.roleType === RoleTypeEnum.STUDENT ||
        selectedRole?.roleType === RoleTypeEnum.CONSERVATOR
      ) {
        const codes = selectedRole?.permCode?.split("-");
        if (codes?.length > 0 && codes?.[3]) {
          await apiCall({
            service: getSchoolInstantMessageConfig,
            serviceParams: codes?.[3],
            onSuccess: (response) => {
              setCanUserCreateMessage(
                response?.data
                  ? selectedRole.roleType === RoleTypeEnum.STUDENT
                    ? response?.data?.studentCreateMessage
                    : response?.data?.conservatorCreateMessage
                  : false
              );
            }
          });
        }
      } else {
        setCanUserCreateMessage(true);
      }
    } catch {
      setCanUserCreateMessage(false);
    }
  };

  const registerMemberCodeListenners = async (existedGroupIds) => {
    setServiceLoad(true);
    apiCall({
      service: getMessageMemberGroupCodes,
      onSuccess: (res) => {
        const memberCodes = res?.data || [];
        for (const memberCode of memberCodes) {
          const path = `instantMessageNewGroup/${memberCode}`;
          listenerPaths.current.push(path);
          onValue(ref(RealTimeDatabase, path), async (newGroup) => {
            const newGroupValue = newGroup?.toJSON();
            if (checkGroupIsNew(newGroupValue, existedGroupIds)) await getChatGroups();
          });
        }
      }
    }).finally(() => setServiceLoad(false));
  };

  const registerGroupListenners = async (groupIds) => {
    for await (const groupId of groupIds) {
      //? Add a small delay to prevent overwhelming the JavaScript thread
      await new Promise((resolve) => setTimeout(resolve, 50));
      const deletePath = `instantMessageDelete/${groupId}`;
      const lastMessagePath = `instantMessages/${groupId}/lastmessage`;
      const lastSeenPath = `instantMessages/${groupId}/lastseen/${userId}`;
      listenerPaths.current.push(deletePath);
      listenerPaths.current.push(lastMessagePath);
      listenerPaths.current.push(lastSeenPath);
      onValue(ref(RealTimeDatabase, deletePath), (response) => {
        if (response?.toJSON()) {
          removeChatGroup(groupId);
        }
      });
      onValue(ref(RealTimeDatabase, lastMessagePath), async (lastMessage) => {
        const lastMessageValue = await lastMessage.toJSON()?.message;
        setLastMessages((prev) => ({
          ...prev,
          [groupId]: lastMessageValue
        }));
      });
      onValue(ref(RealTimeDatabase, lastSeenPath), async (lastSeen) => {
        const lastSeenValue = (await lastSeen.toJSON()?.[userId]) || 0;
        setLastSeens((prev) => ({
          ...prev,
          [groupId]: lastSeenValue
        }));
      });
    }
  };

  const checkGroupIsNew = (newGroup, existedGroupIds) => {
    const roleIds = newGroup?.roleIds?.split("-");
    const isExist = existedGroupIds?.includes(newGroup?.id);
    return (
      (!isExist &&
        now < new Date().getTime() &&
        (newGroup?.groupType === "INDIVIDUAL" ||
          (roleIds?.length && roleIds?.includes(selectedRole?.roleId)) ||
          newGroup?.adminIds?.includes(userId))) ||
      false
    );
  };

  const removeChatGroup = async (groupId) => {
    setLastMessages((prev) => {
      const newLastMessages = { ...prev };
      delete newLastMessages[groupId];
      return newLastMessages;
    });
    setLastSeens((prev) => {
      const newLastSeens = { ...prev };
      delete newLastSeens[groupId];
      return newLastSeens;
    });
    setChatGroups((prev) => prev.filter((p) => p?.id !== groupId));
    // Explicitly remove the listeners for this group
    off(ref(RealTimeDatabase, `instantMessageDelete/${groupId}`));
    off(ref(RealTimeDatabase, `instantMessages/${groupId}/lastmessage`));
    off(ref(RealTimeDatabase, `instantMessages/${groupId}/lastseen/${userId}`));
  };

  return (
    <MessagingContext.Provider
      value={useMemo(
        () => ({
          canUserCreateMessage: canUserCreateMessage,
          chatGroups: chatGroups,
          lastMessages: lastMessages,
          lastSeens: lastSeens,
          loading: serviceLoad,
          unseenMessageCount: unseenMessageCount,
          fireDb: RealTimeDatabase,
          onGroupChatInView: (groupId) => setCurrentMessagingGroup(groupId)
        }),
        [
          chatGroups,
          RealTimeDatabase,
          canUserCreateMessage,
          lastMessages,
          lastSeens,
          serviceLoad,
          unseenMessageCount
        ]
      )}
    >
      {children}
    </MessagingContext.Provider>
  );
}
