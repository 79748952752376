import { IntegrationEnum } from "constants/enums/IntegrationEnum";
import { RoleTypeEnum } from "constants/enums/RoleEnum";

const checkSinavGlobalPermission = (auth, clients, permission) => {
  return clients?.find((client) => client === permission);
};

const checkReadingMachinePermission = (auth, clients, permission) => {
  return auth?.details?.integration?.includes(IntegrationEnum.READING_MACHINE);
};

const checkLiveExamyPermission = (auth, clients, permission) => {
  return auth?.details?.integration?.includes(IntegrationEnum.LIVE_EXAMY);
};
const checkCodeExamyPermission = (auth, clients, permission) => {
  return auth?.details?.integration?.includes(IntegrationEnum.CODE_EXAMY);
};

const checkAllStarPermission = (auth, clients, permission) => {
  return auth?.details?.integration?.includes(IntegrationEnum.ALL_STAR);
};

const checkTracklightPermission = (auth, clients, permission, selectedAuth) => {
  return selectedAuth?.roleType === "STUDENT"
    ? selectedAuth?.details?.integration?.includes(IntegrationEnum.TRACKLIGHT)
    : auth?.integration?.includes(IntegrationEnum.TRACKLIGHT);
};

const checkPreferenceMaticPermission = (auth, clients, permission, selectedAuth) => {
  if (!auth?.details) {
    return permission?.find((level) => level === auth?.levelInfo?.code);
  } else if (auth?.roleType === RoleTypeEnum.STUDENT) {
    return permission?.find((level) => level === auth?.details?.levelInfo?.code);
  }
};

const checkContractShareWithParent = (auth, clients, permission, selectedAuth) => {
  return selectedAuth?.contractShareWithParent;
};

const checkSchoolPermissions = (auth, clients, permission) => {
  if (permission.find((schoolType) => schoolType === auth?.details?.schoolInfo?.schoolType))
    return true;
  else return false;
};

const checkIsConservator = (auth, clients, permission, selectedAuth) => {
  return selectedAuth?.roleType === "CONSERVATOR";
};

const checkIsStudent = (auth, clients, permission, selectedAuth) => {
  return selectedAuth?.roleType === "STUDENT";
};

const checkSupereadPermission = (auth, clients, permission, selectedAuth) => {
  const blockCampusIdList = [
    "62e90d5d716c517e37243fe0" // akçaabat sınav kampüs
  ];
  return !blockCampusIdList.includes(selectedAuth?.permCode?.split("-")[2]);
};

export {
  checkSinavGlobalPermission,
  checkReadingMachinePermission,
  checkPreferenceMaticPermission,
  checkIsConservator,
  checkIsStudent,
  checkSchoolPermissions,
  checkLiveExamyPermission,
  checkContractShareWithParent,
  checkTracklightPermission,
  checkSupereadPermission,
  checkAllStarPermission,
  checkCodeExamyPermission
};
